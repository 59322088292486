"use client"
const questionData = [
  {
    question: "Why wouldn't I just hire a full-time developer?",
    answer: "Good question! For starters, the annual cost of a full-time developer can easily exceed $100,000, plus benefits. Aside from that, you may not always have enough work to keep them busy at all times, so you're stuck paying for time you aren't able to utilize. With the monthly plan, you can pause and resume your subscription as often as you need to ensure you're only paying your developer when you have work available for them.",
    showAnswer: false,
    id: 1,
  },
  {
    question: "Is there a limit to how many requests I can have?",
    answer: "Once subscribed, you're able to add as many project requests to your queue as you'd like, and they will be delivered one by one.",
    showAnswer: false,
    id: 2,
  },
  {
    question: "How fast will I receive my projects?",
    answer: "Timing will be agreed upon prior to beginning work.",
    showAnswer: false,
    id: 3,
  },
  {
    question: "Who are the developers?",
    answer: "Golbi is actually an agency of one. That means you'll work directly with me, founder of Golbi. However, should your project require additional resources Golbi has access to a network of other amazing devs.",
    showAnswer: false,
    id: 4,
  },
  {
    question: "How does the pause feature work?",
    answer: "We understand you may not have enough project work to fill up entire month. Perhaps you only have one or two project requests at the moment. That's where pausing your subscription comes in handy. Billing cycles are based on 31 day period. Let's say you sign up and use the service for 21 days, and then decide to pause your subscription. This means that the billing cycle will be paused and you'll have 10 days of service remaining to be used anytime in the future.",
    showAnswer: false,
    id: 5,
  },
  {
    question: "What programs do you build in?",
    answer: "We will identify and build your project using the technology that best fits your needs.",
    showAnswer: false,
    id: 6,
  },
  {
    question: "How do I request projects?",
    answer: "Golbi offers a ton of flexibility in how you request projects using Trello. Some common ways clients request projects is directly via Trello, sharing Google docs or wireframes, or even recording a brief video (for those who prefer not to write their briefs out). Basically, if it can be linked to or shared in Trello, it's fair game.",
    showAnswer: false,
    id: 7,
  },
  {
    question: "What if I don't like the project?",
    answer: "No worries! We'll continue to make revisions until you're 100% satisfied.",
    showAnswer: false,
    id: 8,
  },
  {
    question: "What if I only have a single request?",
    answer: "That's fine. You can pause your subscription when finished and return  when you have additional project needs. There's no need to let the remainder of your subscription go to waste.",
    showAnswer: false,
    id: 9,
  },
  {
    question: "Are there any refunds if I don't like the service?",
    answer: "Due to the high quality nature of the work, there will be no refunds issued.",
    showAnswer: false,
    id: 10,
  },
]

export default questionData