"use client"
import React from 'react'
import { useRef } from "react";
import { useInView } from "framer-motion";
import doodle2 from '../../src/assets/imgs/doodle2.png'

function Section({ children }) {
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true });

  return (
    <section ref={ref} id="pricing" className='pricing-section'  style={{
      transform: isInView ? "none" : "translateY(340px)",
      opacity: isInView ? 1 : 0,
      transition: "all .9s cubic-bezier(0.17, 0.55, 0.55, 1) .5s"
    }}
  >
      {children}
    </section>
  );
}

export default function Pricing() {
  return (
    <Section>
      <img
      className='doodle-2'
        src={doodle2}
        alt=''
      />
      <div className='pricing-content section-content'>
        <div className='pricing-grid'>
          <div className='pricing-option pricing-option-1'>
            <div className='pricing-subgrid-top'>
              <h1 className='pricing-duration'>Monthly</h1>
              <p className='pricing-desc'>No minimum commitment.<br/>Pause or cancel anytime.</p>
              <h1 className='pricing-price'>$1,995<span>/month</span></h1>
              <div className='pricing-included-container'>
                <p className='pricing-included-header'>What&apos;s included:</p>
                <ul className='pricing-included-list'>
                <li className='pricing-included-item'>- One request at a time</li>
                  <li className='pricing-included-item'>- Unlimited stock photos</li>
                  <li className='pricing-included-item'>- Easy credit-card payments</li>
                  <li className='pricing-included-item'>- Pause or cancel anytime</li>
                </ul>
              </div> 
              <p className="calendar-booking">Book a call to learn more</p>   
            </div>
          
            <button className=" pricing-button button-pushable">
              <span className="button-shadow"></span>
              <span className="button-dark-edge"></span>
              <span className="button-dark-front text">
                Get started
              </span>
            </button>
          </div>

          <div className='pricing-option pricing-option-2'>
            <div className='pricing-subgrid-top'>
              <h1 className='pricing-duration'>Quarterly</h1>
              <p className='pricing-desc'>Save $500 per month.<br/> Billed per quarter.</p>
              <h1 className='pricing-price'>$1,495<span>/month</span></h1>
              <div className='pricing-included-container'>
                <p className='pricing-included-header'>What&apos;s included:</p>
                <ul className='pricing-included-list'>
                <li className='pricing-included-item'>- One request at a time</li>
                  <li className='pricing-included-item'>- Unlimited stock photos</li>
                  <li className='pricing-included-item'>- Easy credit-card payments</li>
                  <li className='pricing-included-item'></li>
                </ul>
              </div>    
              <p className="calendar-booking">Book a call to learn more</p>
            </div>
          
            <button className="pricing-button button-pushable">
              <span className="button-shadow"></span>
              <span className="button-dark-edge"></span>
              <span className="button-dark-front text">
                Get started
              </span>
            </button>
          </div>

       
          <div className='pricing-option pricing-option-3 flex flex-col justify-center items-center text-center'>
            <p className='pricing-cta-copy pricing-cta-copy-1'>Think your project might take less than one month to complete?</p>
            <p className='pricing-cta-copy pricing-cta-copy-2'>Let&apos;s have a call to see if a project based quote might fit you better.</p>

            <button className="button-pushable">
              <span className="button-shadow"></span>
              <span className="button-accent-edge"></span>
              <span className="button-accent-front text">
                Schedule now
              </span>
            </button>

            <p className="pricing-cta-email">Prefer to email?<br></br> <span>letschat@golbi.co</span></p>

            <div className='pricing-radar-container'>
              <span className="pricing-radar-text">Now accepting new clients!</span>
              <span className="radar"></span>
            </div>
          </div>
        </div>
      </div>
    </Section>
  )
}